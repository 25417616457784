import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

const Review_lazyPromise = import('./containers/Reviews');
let Review_lazy = lazy(() => Review_lazyPromise);

const ConfirmReservation_lazyPromise = import(
  './containers/ConfirmReservation'
);
let ConfirmReservation_lazy = lazy(() => ConfirmReservation_lazyPromise);

const ConfirmOrder_lazyPromise = import('./containers/ConfirmOrder');
let ConfirmOrder_lazy = lazy(() => ConfirmOrder_lazyPromise);

const OrderTracker_lazyPromise = import('./containers/OrderTracker');
let OrderTracker_lazy = lazy(() => OrderTracker_lazyPromise);

const Home_lazyPromise = import('./containers/Home');
let Home_lazy = lazy(() => Home_lazyPromise);

export default () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<div />}>
        <Switch>
          {/* <Route
            path='/order-review'
            render={props => <Review_lazy {...props} />}
          /> */}
          <Route
            path='/order-tracking'
            render={(props) => <OrderTracker_lazy {...props} />}
          />
          {/* <Route
            path='/confirm-booking'
            render={(props) => <ConfirmReservation_lazy {...props} />}
          />
          <Route
            path='/confirm-order'
            render={(props) => <ConfirmOrder_lazy {...props} />}
          /> */}
          <Route
            path='/'
            render={(props) => <OrderTracker_lazy {...props} />}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
